import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import { login, logout, loggedIn, setUser } from './features/user/userSlice.js'
import FLEETAPI, { apiDOWG } from './connections/api';
import ecolog from './EcoLogIcon-white-background.png'
import styles from './login.module.css';

axios.defaults.headers.post['Content-Type'] = 'application/json';

function LoginPage() {
  const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
  const [apiError, setApiError] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setApiError(""); // Clear previous errors before submitting
    clearErrors("password"); // Ensure previous errors are cleared

    try {
      const result = await FLEETAPI.post("user/login", data);

      if (result.data.accessToken) {
        console.log("Login successful:", result);
        localStorage.setItem("token", result.data.accessToken);
        FLEETAPI.defaults.headers.common["Authorization"] = `Bearer ${result.data.accessToken}`;
        apiDOWG.defaults.headers.common['Authorization'] = `Bearer ${result.data.accessToken}`;
        localStorage.setItem("loggedin", true);
        dispatch(loggedIn(true));

        const self = await FLEETAPI.post("user/self");
        let suy = self.data.pop();
        dispatch(setUser(suy));
        navigate("/mainpage");
      }
    } catch (err) {
      if (err.response) {
        const { status, data } = err.response;

        if (status === 403) {
          setError("password", { type: "manual", message: data.message || "Felaktigt lösenord. Försök igen!" });
        } else if (status === 404) {
          setError("name", { type: "manual", message: "Ingen användare hittades. Kontrollera användarnamnet." });
        } else {
          setApiError(data?.message || "Något gick fel. Försök igen senare.");
        }
      } else {
        setApiError("Något gick fel. Försök igen senare.");
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.form}>
          {/*<div>
            <img src={insigtBlue} alt="logo" width="200" />
          </div>
          */}
          <div style={{ width: 160 }}>
            <img src={ecolog} alt="logo" />
          </div>
          {/*<h3 className={styles.loginTxt}>Logga in med användarnamn</h3>*/}

          <form className={styles.forminput} onSubmit={handleSubmit(onSubmit)}>
            <input type="text" placeholder="Användarnamn" {...register("name", { required: true })} />
            {errors.name && <span>{errors.name.message}</span>}

            <input type="password" placeholder="Lösenord" {...register("password", { required: true })} />
            {errors.password && <span>{errors.password.message}</span>}

            {apiError && <span className={styles.error}>{apiError}</span>}

            <button className="buttonLogIn button2" type="submit">Logga in</button>
          </form>
          <p className={styles.txt}>
            EcoLog är godkända skogsmaskiner produkter med CE-märkning.
            All data överförs krypterat och behandlas enligt personuppgiftslagen, och EU:s dataskyddsförordning (GDPR).
          </p>

          <div className={styles.txt_link}>
            <a href="https://ecologforestry.com/integritetspolicy" rel="noreferrer" target="_blank">Läs mer om vår datahantering</a>
            <a style={{ paddingLeft: 30 }} href="https://ecologforestry.com/se" rel="noreferrer" target="_blank">Läs mer om EcoLog</a>
          </div>
        </div>
      </div>
      {/* <p style={{ color: 'white'}}>{status}</p> */}
    </div>
  )
}

export default LoginPage

