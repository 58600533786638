import React from 'react'
import { useSpring, animated } from '@react-spring/web'

export const BackArrowLineComp = ({ fillBody = "#353E42", fillLine = "#F1F1F1", onclick }) => {
  const [{ opacity, x }] = useSpring(
    () => ({
      from: { opacity: 0, scale: 0.2, x: -100 },
      to: { opacity: 1, scale: 1, x: 0 },
      //config: { duration: 2000 },
    }),
  )

  return (
    <animated.svg width="70" height="30" viewBox="0 0 70 30" fill="none" xmlns="http://www.w3.org/2000/svg"
      onClick={onclick}
      style={{
        //backgroundColor,
        opacity,
        x
      }}
    >
      <g opacity="0.8">
        <rect opacity="0.8" width="70" height="30" rx="15" fill={fillBody} />
        <path d="M45 16C45.5523 16 46 15.5523 46 15C46 14.4477 45.5523 14 45 14V16ZM9.29289 14.2929C8.90237 14.6834 8.90237 15.3166 9.29289 15.7071L15.6569 22.0711C16.0474 22.4616 16.6805 22.4616 17.0711 22.0711C17.4616 21.6805 17.4616 21.0474 17.0711 20.6569L11.4142 15L17.0711 9.34315C17.4616 8.95262 17.4616 8.31946 17.0711 7.92893C16.6805 7.53841 16.0474 7.53841 15.6569 7.92893L9.29289 14.2929ZM45 14L27.5 14V16L45 16V14ZM27.5 14L10 14V16H27.5V14Z" fill={fillLine} />
      </g>
    </animated.svg>
  )
}