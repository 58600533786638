import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
//import axios from 'axios';
import { login, logout, setUser, logoutSuccess } from './features/user/userSlice.js'
import FLEETAPI from './connections/api';

function Top() {
  const User = useSelector((state) => state.user?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const selfReq = async () => {
      // let token = localStorage.getItem('token');
      // if(token) {
      //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      // }
      try {
        //const result = await axios.post('http://localhost:3000/user/self');
        const result = await FLEETAPI.post('user/self');
        let suy = result.data.pop();

        console.log(suy)
        dispatch(setUser(suy))
      } catch (err) {
        console.log(err);
        dispatch(logoutSuccess());
      }
    }

    //selfReq();
  }, []);

  return (
    <div className="top">
      <div className="header-logo">ECOLOG INSIGHT</div>
      <div className="headerFlex1">
        <NavLink to="/mainpage/embedded"
          className={({ isActive }) => isActive ? "no-active" : ""}>
          {/* <img src={imgSupport} alt="system" /> */}
          <div className="div_a">NexSci</div>
        </NavLink>
        <NavLink to="/mainpage/docs"
          className={({ isActive }) => isActive ? "no-active" : ""}
        >
          {/* <img src={imgSupport} alt="system" /> */}
          <div className="div_a">ForesterDocs</div>
        </NavLink>
        <NavLink to="/mainpage/machinedocs"
          className={({ isActive }) => isActive ? "no-active" : ""}
        >
          {/* <img src={imgSupport} alt="system" /> */}
          <div className="div_a">MachineDocs</div>
        </NavLink>

        <NavLink to="/mainpage/releasedocs"
          className={({ isActive }) => isActive ? "no-active" : ""}
        >
          {/* <img src={imgSupport} alt="system" /> */}
          <div className="div_a">ReleaseDocs</div>
        </NavLink>

      </div>
      <div className="header-user">
        {User?.userFirstName} - {User?.userLastName}
        <div className="header-user_type" >
          {User?.userType}
        </div>
      </div>
      <div style={{ marginRight: '10px', alignSelf: 'flex-end' }}
        onClick={() => {
          let res = dispatch(logout());
          //console.log(res.value)
        }}
      >
        <Icon />
      </div>
    </div>
  )
}

export default Top;

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#000"
      version="1.1"
      viewBox="0 0 384.971 384.971"
      xmlSpace="preserve"
    >
      <g fill="white">
        <path d="M180.455 360.91H24.061V24.061h156.394c6.641 0 12.03-5.39 12.03-12.03s-5.39-12.03-12.03-12.03H12.03C5.39.001 0 5.39 0 12.031V372.94c0 6.641 5.39 12.03 12.03 12.03h168.424c6.641 0 12.03-5.39 12.03-12.03.001-6.641-5.389-12.03-12.029-12.03z"></path>
        <path d="M381.481 184.088l-83.009-84.2a11.942 11.942 0 00-17.011 0c-4.704 4.74-4.704 12.439 0 17.179l62.558 63.46H96.279c-6.641 0-12.03 5.438-12.03 12.151s5.39 12.151 12.03 12.151h247.74l-62.558 63.46c-4.704 4.752-4.704 12.439 0 17.179a11.931 11.931 0 0017.011 0l82.997-84.2c4.644-4.68 4.692-12.512.012-17.18z"></path>
      </g>
    </svg>
  );
}