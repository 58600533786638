import React from "react";

import styles from "./tableIO.module.css";

const Led = () => (
  <div
    className="tableIO2-stateCircle"
    style={{
      backgroundColor: true && "greenyellow",
    }}
  ></div>
);

const Row = ({ machine, onRowClick }) => {
  const { machineId, engine, serialnr, type, machineName } = machine;

  // Handle the click and pass the machineId to the parent (via TableIoDef, to App)
  const handleRowClick = () => {
    console.log('MachineId from Row:', machineId);
    onRowClick(machineId);  // Send machineId up to the grandparent component (App)
  };

  return (
    <tr onClick={handleRowClick}>
      <td><Led /></td>
      <td>{machineId}</td>
      <td>{engine}</td>
      <td>{serialnr}</td>
      <td>{type}</td>
      <td>{machineName}</td>
    </tr>
  );
};

const TableIoDef = ({ dataIO = [], onMachineClick }) => {

  return (
    <div className={styles.mainTableDef}>
      <table className={styles.tableIO_main}>
        <thead>
          <tr>
            <th>Running</th>
            <th>MachineId</th>
            <th>Engine</th>
            <th>Serialnr</th>
            <th>Type</th>
            <th>Machine Name</th>
          </tr>
        </thead>
        <tbody>
          {dataIO?.map((machine) => (
            <Row 
              key={machine.machineId} 
              machine={machine} 
              onRowClick={onMachineClick} 
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableIoDef;



