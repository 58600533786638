import axios from 'axios';

export default axios.create({
  baseURL: `https://ecologportal.duckdns.org/fleetapi/`,
  //baseURL: `http://localhost:3000/`
});

export const apiDOWG = axios.create({
  //baseURL: `https://hmi.ecologgateway/api/`,
  baseURL: `https://ecologportal.duckdns.org/fleetapi/machine/autdata`,
  //baseURL: `${baseUrl}/api/`
});