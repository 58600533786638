import React, { memo } from 'react'

import styles from './AllserviceTable.module.css';

const Row = ({ service }) => {
  const { 
    ServiceId, 
    ServiceDealer, 
    ServicePerformedDate, 
    ServiceComment,
    ServiceIntervalHour, 
    ServiceMachienId, 
    ServiceMachineHours, 
    ServicePersName, 
    ServiceVPnr
  } = service;

  // Format ServicePerformedDate to local time in Swedish locale
  const formattedDate = new Date(ServicePerformedDate).toLocaleString('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // 24-hour format
  });

  return (
    <tr>
      <td>{ServiceMachienId}</td>
      <td>{ServiceDealer}</td>
      <td>{formattedDate}</td>
      <td>{ServiceComment}</td>
      <td>{ServiceIntervalHour}</td>
      <td>{ServiceMachineHours}</td>
      <td>{ServicePersName}</td>
      <td>{ServiceVPnr}</td>
    </tr>
  );
};


const Table = ({ data }) => (
  <tbody>
    {data?.map((service) => (
      <Row key={service.ServiceId} service={service} />
      ))
    }
  </tbody>
)

const TableIoDef = ({ dataIO }) => {
  console.log('dataIO', dataIO)
  return (
    <>
      <div className={styles.mainTableDef}>
        <table className={styles.tableIO_main}>
          <thead>
            <tr>
              <th>MachienId</th>
              <th>Dealer</th>
              <th>PerformedDate</th>
              <th>Comment</th>
              <th>IntervalHour</th>
              <th>MachineHours</th>
              <th>PersName</th>
              <th>VP</th>
            </tr>
          </thead>
          <Table data={dataIO} />
        </table >
      </div>
    </>
  );
}

export const AllserviceTable = memo(TableIoDef)